@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Source Sans 3", sans-serif;

  

}

h1,h2,h3,h4{

  font-family: "Oswald", sans-serif;



}

.fa-circle-xmark:hover{

  color: #dc2626;

}


@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}




@media screen and (max-width: 400px){

  .hide-footer{

    display: none;

  }

}